import axios from 'axios';
import ReactOnRails from 'react-on-rails';

import i18next from '../i18n';

const request = (method, path, options) =>
  axios({
    baseURL: '/gate',
    method,
    url: path,
    ...options,
    headers: {
      'X-Key-Inflection': 'camel',
      'Content-Type': 'application/json',
      Locale: i18next.language,
      ...ReactOnRails.authenticityHeaders(options.headers),
    },
  });

const Gate = {
  get: (path, options = {}) => request('get', path, options),
  post: (path, options = {}) => request('post', path, options),
  delete: (path, options = {}) => request('delete', path, options),
};

export default Gate;
