import BaseIcon from 'components/Elements/Icon';
import styled, { css } from 'styled-components';
import { colors } from 'styles/config';

export const InputContainer = styled.div`
  background: ${colors.whitesmoke};
  box-sizing: border-box;
  position: relative;
  border: 0.1rem solid ${colors.whitesmoke};
  margin-bottom: 1rem;
`;

export const Input = styled.input`
  height: 5rem !important;
  min-width: 100%;
  padding: 0.8rem 1.4rem;
  background: none !important;
  border: none;
  font-size: 1.3rem !important;
  font-weight: 300 !important;
  font-family: 'Montserrat';
  letter-spacing: 0.02rem;
  appearance: none;

  &:focus {
    outline-offset: 0;
  }

  ::placeholder {
    color: ${colors.boulder} !important;
  }
`;

export const Icon = styled(BaseIcon)`
  display: flex;
  position: absolute;
  height: 100%;
  width: 4.4rem;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: space-around;
  font-size: 2.1rem;
  color: ${colors.dimgray};
  pointer-events: none;
`;

export const Results = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 2rem;
`;

export const ResultGroup = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ResultGroupTitle = styled.h5`
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: ${colors.dimgray};
  letter-spacing: 0.02rem;
  font-size: 1.4rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
  justify-content: flex-start;
`;
