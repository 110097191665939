import { MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import LocaleSwitcher from 'components/Elements/LocaleSwitcher';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Search from './Search';
import StaticPageLink from './StaticPageLink';
import { Inner, Link, Navigation as NavigationContainer } from './styles';

const Navigation = ({ rootUrl, blogUrl, availableLocales, currentLocale }) => {
  const { t } = useTranslation();
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);

  const hideSideMenu = useCallback(() => {
    setSideMenuOpen(false);
  }, []);

  const showSideMenu = useCallback(e => {
    e.preventDefault();
    setSideMenuOpen(true);
  }, []);

  const closeSearchModal = useCallback(() => {
    setSearchModalOpen(false);
  }, []);

  const handleSearchClick = useCallback(event => {
    event.preventDefault();
    hideSideMenu();
    setSearchModalOpen(true);
  }, []);

  return (
    <Inner>
      <NavigationContainer className="hidden lg:flex">
        <StaticPageLink locale={currentLocale} page="owners" rootUrl={rootUrl} />
        <StaticPageLink locale={currentLocale} page="partners" rootUrl={rootUrl} />
        <StaticPageLink locale={currentLocale} page="about_the_company" rootUrl={rootUrl} />{' '}
        <Link href={blogUrl} target="_blank">
          Blog
        </Link>
        <StaticPageLink locale={currentLocale} page="contact_us" rootUrl={rootUrl} />
        <Link href="#" onClick={handleSearchClick}>
          {t('searchModal.navigationItem')}
        </Link>
        <LocaleSwitcher availableLocales={availableLocales} currentLocale={currentLocale} />
      </NavigationContainer>
      <Drawer
        bodyStyle={{ padding: '20px 0' }}
        closable={false}
        open={isSideMenuOpen}
        placement="right"
        title={null}
        zIndex={2005}
        onClose={hideSideMenu}
      >
        <StaticPageLink locale={currentLocale} page="owners" rootUrl={rootUrl} />
        <StaticPageLink locale={currentLocale} page="partners" rootUrl={rootUrl} />
        <StaticPageLink locale={currentLocale} page="about_the_company" rootUrl={rootUrl} />
        <Link href={blogUrl} target="_blank">
          Blog
        </Link>
        <StaticPageLink locale={currentLocale} page="contact_us" rootUrl={rootUrl} />
        <Link href="#" onClick={handleSearchClick}>
          {t('searchModal.navigationItem')}
        </Link>
        <LocaleSwitcher availableLocales={availableLocales} currentLocale={currentLocale} />
      </Drawer>
      <button className="lg:hidden flex items-center justify-around px-6" onClick={showSideMenu}>
        <MenuOutlined className="text-4xl leading-normal flex items-center" />
      </button>
      <Search isOpen={isSearchModalOpen} locale={currentLocale} rootUrl={rootUrl} onClose={closeSearchModal} />
    </Inner>
  );
};

export default Navigation;
