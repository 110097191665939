import uniqueId from 'lodash/uniqueId';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { canUseDOM } from 'utils/helpers';

import ModalExpanded from './Expanded/Modal';
import ModalMinimal from './Minimal/Modal';

const Modal = ({ minimal, id, visible, title, children, scrollable, hideModal, className }) => {
  const modalTarget = useRef(document.createElement('div'));
  const ModalComponent = useMemo(() => (minimal ? ModalMinimal : ModalExpanded), [minimal]);

  const handleKeydown = useCallback(event => {
    if (event.keyCode === 27) {
      hideModal();
    }
  }, []);

  useEffect(() => {
    modalTarget.current.className = id || uniqueId('modal-');
    document.body.appendChild(modalTarget.current);

    if (visible) {
      document.addEventListener('keydown', handleKeydown, false);
    } else {
      document.removeEventListener('keydown', handleKeydown, false);
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
      document.body.removeChild(modalTarget.current);
    };
  }, [visible]);

  if (!visible) {
    return null;
  }

  return createPortal(
    <ModalComponent className={className} hideModal={hideModal} scrollable={scrollable} title={title} visible={visible}>
      {children}
    </ModalComponent>,
    modalTarget.current
  );
};

const withCanUseDOM = Component => props => {
  if (!canUseDOM()) {
    return null;
  }
  return <Component {...props} />;
};

export default withCanUseDOM(Modal);
