import i18n from 'i18next';
import translations from 'translations';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const DEFAULT_LANGUAGE = 'pl';
const OTHER_LANGUAGES = ['pl', 'en', 'de'];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translations,
    fallbackLng: DEFAULT_LANGUAGE,
    preload: OTHER_LANGUAGES,
    defaultLanguage: DEFAULT_LANGUAGE,
    otherLanguages: OTHER_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    whitelist: OTHER_LANGUAGES,
    debug: process.env.NODE_ENV !== 'production',
    preload: ['pl'],
    react: {
      useSuspense: false,
    },
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      caches: false,
    },
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
  });

export default i18n;
