import onClickOutside from 'react-onclickoutside';
import { compose, withHandlers } from 'recompose';

import CloseButton from './CloseButton';
import { ModalBoxContainer as Container } from './styles';

const enhance = compose(
  withHandlers({
    handleClickOutside: props => () => props.hideModal(),
  }),
  onClickOutside
);

const ModalBox = ({ children, hideModal }) => (
  <Container>
    <CloseButton data-role="modal-close-button" hideModal={hideModal} />
    {children}
  </Container>
);

export default enhance(ModalBox);
