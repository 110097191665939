import { compose, withProps } from 'recompose';

import CloseButton from './CloseButton';
import { Container, ModalContent, ModalHeader, ModalTitle } from './styles';

const enhance = compose(
  withProps(props => ({
    handleCloseClick: event => {
      event.preventDefault();
      props.hideModal();
    },
  }))
);

const ModalBox = ({ children, handleCloseClick, title }) => (
  <Container>
    <ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      <CloseButton handleCloseClick={handleCloseClick} />
    </ModalHeader>
    <ModalContent>{children}</ModalContent>
  </Container>
);

export default enhance(ModalBox);
