/* eslint-disable */
export default {
  pl: {
    translation: {
      routes: {
        search: 'szukaj',
        booking: 'rezerwacja',
        payment: 'platnosc',
        mission_and_values: 'misja-i-wartosci',
        about_the_company: 'o-firmie',
        terms_and_conditions_of_service: 'regulamin',
        terms_and_conditions_of_loyalty_card: 'regulamin-karta-stalego-goscia',
        privacy_policy: 'polityka-prywatnosci',
        contact_us: 'kontakt',
        owners: 'wlasciciele',
        partners: 'partnerzy',
        investments: 'inwestycje',
        'payment-error': 'platnosc/blad',
      },
      navigation: {
        contact: 'Kontakt',
        login: 'Zaloguj',
        logout: 'Wyloguj',
        register: 'Zarejestruj się',
        email: 'E-mail',
        password: 'Hasło',
        incorrect_credentials: 'Nieprawidłowy e-mail lub hasło.',
        registration_failed: 'Coś poszło nie tak. Spróbuj ponownie.',
        registration_success: 'Konto utworzone, zaloguj się.',
        first_name: 'Imię',
        last_name: 'Nazwisko',
        phone: 'Telefon',
        country: 'Kraj',
        terms_accept: 'Akceptuję Regulamin Świadczenia Usług',
        offers_accept: 'Chcę otrzymywać vouchery, porady turystyczne oraz aktualności.',
        about_us: 'O nas',
        about_the_company: 'O firmie',
        mission_and_values: 'Misja i Wartości',
        terms_and_conditions_of_service: 'Regulamin Świadczenia Usług',
        terms_and_conditions_of_loyalty_card: 'Regulamin Karty Stałego Gościa',
        privacy_policy: 'Polityka prywatności',
        contact_us: 'Kontakt',
        partnership: 'Współpraca',
        owners: 'Właściciele',
        partners: 'Partnerzy',
        investments: 'Inwestycje',
      },
      searchModal: {
        navigationItem: 'Szukaj',
        title: 'Wyszukiwanie',
        noResults: 'Brak wyników',
        inputPlaceholder: 'Np. miejscowość, obiekt, apartament',
        accommodations: 'Apartamenty',
        properties: 'Obiekty',
        locations: 'Miejscowości',
      },
      searchForm: {
        arrival: 'Przyjazd',
        departure: 'Wyjazd',
        location: 'Lokalizacja',
        clear: 'Wyczyść',
        searchApartments: 'Wyszukaj apartament:',
        searchText: 'Szukaj',
        locationGroups: {
          mountains: 'Góry',
          seaside: 'Morze',
          regions: 'Według regionu',
        },
      },
      bookingPage: {
        form: {
          bookNow: 'Zarezerwuj i zapłać',
          termsAccept: 'Przeczytałem i akceptuję <2>Regulamin Świadczenia Usług</2> i <6>Zasady Rezerwacji</6>',
          bookingPolicies: 'Zasady rezerwacji',
          privacyPolicy: 'Polityka Prywatności',
          privacyPolicyText:
            'Poprzez kliknięcie przycisku „Zarezerwuj i zapłać” wyrażasz zgodę na przetwarzanie Twoich danych osobowych w celu realizacji rezerwacji. Administratorem twoich danych jest SALUS - HRM Sp. z o.o., pl. Powstańców Śląskich 17A/222, 53-329 Wrocław',
          guaranteeText:
            'Rezerwacja gwarantowana jest przez 18 godzin. Informacje dotyczące płatności oraz szczegóły rezerwacji automatycznie zostaną przesłane na podany adres e-mail.',
        },
        steps: {
          place: 'Miejsce',
          yourDetails: 'Twoje dane',
          payment: 'Płatność',
          summary: 'Podsumowanie',
        },
        summary: {
          summary: 'Podsumowanie',
          arrival: 'Przyjazd:',
          departure: 'Wyjazd:',
          guests: 'Liczba gości:',
          costs: 'Koszty',
          total: 'Razem:',
          prepayment: 'Przedpłata',
          uponArrival: 'Na miejscu',
        },
      },
      costCalculation: {
        clear: 'Wyczyść',
        change: 'Zmień',
        yourStay: 'Twój pobyt',
        total: 'Razem',
        prepayment: 'Przedpłata',
        uponArrival: 'Na miejscu',
        bookNow: 'Zarezerwuj teraz',
        book: 'Rezerwuj',
        costsSummary: 'Podsumowanie kosztów:',
        chooseDates: 'Wybierz termin',
        additionalCosts: {
          serviceCharge: 'Opłata serwisowa',
          touristTaxTotal: 'Opłata klimatyczna',
        },
        summaryNightsCount_zero: '0 nocy × {{price}}',
        summaryNightsCount_one: '1 noc × {{price}}',
        summaryNightsCount_two: '{{count}} noce × {{price}}',
        summaryNightsCount_few: '{{count}} noce × {{price}}',
        summaryNightsCount_many: '{{count}} nocy × {{price}}',
        summaryNightsCount_other: '{{count}} nocy × {{price}}',
        form: {
          arrival: 'Przyjazd:',
          departure: 'Wyjazd:',
          guests: 'Liczba gości:',
          messages: {
            available: 'Termin jest dostępny. Zarezerwuj teraz!',
            unavailable: 'Termin jest niedostępny. Spróbuj znaleźć inny termin.',
            chooseTerm: 'Wybierz termin pobytu i liczbę gości.',
            minNightsUnfulfilled: 'W tym terminie minimalna liczba nocy wynosi {{count}}.',
          },
        },
      },
      payment: {
        title: 'Płatność',
        waiting: 'Oczekiwanie na potwierdzenie płatności...',
      },
      thankYou: {
        title: 'Dziękujemy za dokonanie płatności.',
        line1: 'Cieszymy się, że będziesz wypoczywać razem z nami.',
        line2: 'Wkrótce otrzymasz wiadomość e-mail potwierdzającą Twoją rezerwację.',
        line3: 'W razie potrzeby skontaktuj się z Centrum Obsługi Klienta:',
        phone: 'Telefon:',
        email: 'E-mail:',
      },
      paymentError: {
        title: 'Dokończ swoją rezerwację.',
        line1: 'Aby potwierdzić rezerwację konieczne jest dokonanie płatności.',
        line2: 'Kliknij <1>tutaj</1>, aby zapłacić.',
      },
      searchPage: {
        resultsCount_zero: '<0>{{location}}:</0> nie znaleziono dostępnych apartamentów',
        resultsCount_one: '<0>{{location}}:</0> znaleziono <3>{{count}}</3> apartament',
        resultsCount_two: '<0>{{location}}:</0> znaleziono <3>{{count}}</3> apartamenty',
        resultsCount_few: '<0>{{location}}:</0> znaleziono <3>{{count}}</3> apartamenty',
        resultsCount_many: '<0>{{location}}:</0> znaleziono <3>{{count}}</3> apartamentów',
        resultsCount_other: '<0>{{location}}:</0> znaleziono <3>{{count}}</3> apartamentów',
        resultsCountNoLocation_zero: 'Nie znaleziono dostępnych apartamentów',
        resultsCountNoLocation_one: 'Znaleziono <1>{{count}}</1> apartament',
        resultsCountNoLocation_two: 'Znaleziono <1>{{count}}</1> apartamenty',
        resultsCountNoLocation_few: 'Znaleziono <1>{{count}}</1> apartamenty',
        resultsCountNoLocation_many: 'Znaleziono <1>{{count}}</1> apartamentów',
        resultsCountNoLocation_other: 'Znaleziono <1>{{count}}</1> apartamentów',
      },
      from: 'od',
      book: 'Rezerwuj',
      accommodationNameFormatted: '<1>Apartament</1> <0>{{accommodationName}}</0>',
      bedroomCount_zero: 'Studio',
      bedroomCount_one: '1 sypialnia',
      bedroomCount_two: '{{count}} sypialnie',
      bedroomCount_few: '{{count}} sypialnie',
      bedroomCount_many: '{{count}} sypialni',
      bedroomCount_other: '{{count}} sypialni',
      guestCount_zero: '{{count}} gości',
      guestCount_one: '{{count}} gość',
      guestCount_two: '{{count}} gości',
      guestCount_few: '{{count}} gości',
      guestCount_many: '{{count}} gości',
      guestCount_other: '{{count}} gości',
      checkOnMap: 'Sprawdź na mapie',
      forms: {
        errors: {
          required: 'Pole jest wymagane',
          email: 'Niepoprawny format e-mail',
        },
      },
    },
  },
  en: {
    translation: {
      routes: {
        search: 'search',
        booking: 'booking',
        payment: 'payment',
        mission_and_values: 'mission-and-values',
        about_the_company: 'about-the-company',
        terms_and_conditions_of_service: 'terms-and-conditions-of-service',
        terms_and_conditions_of_loyalty_card: 'terms-and-conditions-loyalty-card',
        privacy_policy: 'privacy-policy',
        contact_us: 'contact-us',
        owners: 'owners',
        partners: 'partners',
        investments: 'investments',
        'payment-error': 'payment/error',
      },
      navigation: {
        contact: 'Contact',
        login: 'Login',
        logout: 'Logout',
        register: 'Register',
        email: 'E-mail',
        password: 'Password',
        incorrect_credentials: 'Invalid e-mail or password.',
        registration_failed: 'Something went wrong. Try again.',
        registration_success: 'Account created, you can log in.',
        first_name: 'First name',
        last_name: 'Last name',
        phone: 'Phone',
        country: 'Country',
        terms_accept: 'I accept the Terms of service.',
        offers_accept: "I'd like to receive vouchers, travel tips and news.",
        about_us: 'About us',
        about_the_company: 'About the Company',
        mission_and_values: 'Mission and Values',
        terms_and_conditions_of_service: 'Terms and Conditions of Service',
        terms_and_conditions_of_loyalty_card: 'Terms and conditions of Loyalty Card Program',
        privacy_policy: 'Privacy policy',
        contact_us: 'Contact us',
        partnership: 'Partnership',
        owners: 'Owners',
        partners: 'Partners',
        investments: 'Investments',
      },
      searchModal: {
        navigationItem: 'Search',
        title: 'Search',
        noResults: 'No results',
        inputPlaceholder: 'E.g. city, property, apartment',
        accommodations: 'Apartments',
        properties: 'Properties',
        locations: 'Locations',
      },
      searchForm: {
        arrival: 'Arrival',
        departure: 'Departure',
        location: 'Location',
        clear: 'Clear',
        searchApartments: 'Search apartments:',
        searchText: 'Search',
        locationGroups: {
          mountains: 'Mountains',
          seaside: 'Seaside',
          regions: 'By region',
        },
      },
      bookingPage: {
        form: {
          bookNow: 'Book now & pay',
          book: 'Book now',
          termsAccept: 'I have read and accepted the <2>Terms of service</2> and <6>Booking policies</6>',
          bookingPolicies: 'Booking policies',
          privacyPolicy: 'Privacy Policy',
          privacyPolicyText:
            'By clicking the „Book now & pay” button you agree to the processing of your personal data in order to complete the reservation. The administrator of your personal data is SALUS - HRM Sp. z o.o., pl. Powstańców Śląskich 17A/222, 53-329 Wrocław',
          guaranteeText:
            'The reservation is guaranteed for 18 hours. Payment information and booking details will be automatically sent to the email address provided.',
        },
        steps: {
          place: 'Place',
          yourDetails: 'Your details',
          payment: 'Payment',
          summary: 'Summary',
        },
        summary: {
          summary: 'Summary',
          arrival: 'Arrival:',
          departure: 'Departure:',
          guests: 'Guests:',
          costs: 'Costs',
          total: 'Total:',
          prepayment: 'Prepayment',
          uponArrival: 'Upon arrival',
        },
      },
      costCalculation: {
        clear: 'Clear',
        change: 'Change',
        yourStay: 'Your stay',
        total: 'Total',
        prepayment: 'Prepayment',
        uponArrival: 'Upon arrival',
        bookNow: 'Book now',
        book: 'Book now',
        costsSummary: 'Costs summary:',
        chooseDates: 'Choose dates',
        additionalCosts: {
          serviceCharge: 'Service charge',
          touristTaxTotal: 'Tourist tax',
        },
        summaryNightsCount: '{{count}} nights × {{price}}',
        form: {
          arrival: 'Arrival:',
          departure: 'Departure:',
          guests: 'Guests:',
          messages: {
            available: 'Dates available. Book now!',
            unavailable: 'Dates unavailable. Try with a different daterange.',
            chooseTerm: 'Choose the dates of your stay and the number of guests.',
            minNightsUnfulfilled: 'The minimum number of nights for these dates is {{count}}.',
          },
        },
      },
      payment: {
        title: 'Payment',
        waiting: 'Awaiting payment confirmation',
      },
      thankYou: {
        title: 'Thank you for making the payment.',
        line1: 'We are very glad that you will stay at our apartments.',
        line2: 'You will receive an email confirming your booking shortly.',
        line3: 'In case of any questions, contact our Customer Service:',
        phone: 'Phone:',
        email: 'E-mail:',
      },
      paymentError: {
        title: 'Finish your reservation.',
        line1: 'It is necessary to make a payment to confirm the reservation.',
        line2: 'Click <1>here</1> to make the payment.',
      },
      searchPage: {
        resultsCount: '<0>{{location}}:</0> displaying <3>{{count}}</3> apartments',
        resultsCountNoLocation: 'Displaying <1>{{count}}</1> apartments',
      },
      from: 'from',
      book: 'Book',
      accommodationNameFormatted: '<0>{{accommodationName}}</0><1>Apartment</1>',
      bedroomCount_zero: 'Studio',
      bedroomCount_one: '1 bedroom',
      bedroomCount_other: '{{count}} bedrooms',
      guestCount_one: '1 guest',
      guestCount_other: '{{count}} guests',
      checkOnMap: 'Check on a map',
      forms: {
        errors: {
          required: 'Field is required',
          email: 'Incorrect e-mail format',
        },
      },
    },
  },
  de: {
    translation: {
      routes: {
        search: 'suche',
        booking: 'buchung',
        payment: 'zahlung',
        mission_and_values: 'mission-und-verte',
        about_the_company: 'uber-die-firma',
        terms_and_conditions_of_service: 'allgemeine-geschaftsbedingungen',
        terms_and_conditions_of_loyalty_card: 'allgemeine-geschaeftsbedingungen-stammkundenkarte',
        privacy_policy: 'datenschutzerklarung',
        contact_us: 'kontakt',
        owners: 'besitzer',
        partners: 'partner',
        investments: 'investitionen',
        'payment-error': 'zahlung/error',
      },
      navigation: {
        contact: 'Kontakt',
        login: 'Login',
        logout: 'Ausloggen',
        register: 'Registrieren',
        email: 'E-mail',
        password: 'Passwort',
        incorrect_credentials: 'Ungültige E-mail or Passwort',
        registration_failed: 'Etwas ist schief gelaufen. Versuch es noch einmal.',
        registration_success: 'Konto erstellt, Sie können sich anmelden.',
        first_name: 'Vorname',
        last_name: 'Nachname',
        phone: 'Telefon',
        country: 'Land',
        terms_accept: 'Ich akzeptiere die Servicebedingungen',
        offers_accept: 'Ich möchte Gutscheine, Reisetipps und Neuigkeiten erhalten.',
        about_us: 'Über uns',
        about_the_company: 'Über die Firma',
        mission_and_values: 'Mission und Werte',
        terms_and_conditions_of_service: 'Allgemeine Geschäftsbedingungen',
        terms_and_conditions_of_loyalty_card: 'Reglement des Programms STAMMKUNDENKARTE',
        privacy_policy: 'Datenschutzerklärung',
        contact_us: 'Kontakt',
        partnership: 'Partnerschaft',
        owners: 'Besitzer',
        partners: 'Partner',
        investments: 'Investitionen',
      },
      searchModal: {
        navigationItem: 'Suche',
        title: 'Suche',
        noResults: 'Keine Suchergebnisse',
        inputPlaceholder: 'Z.B. Stadt, Aparthotel, Apartment',
        accommodations: 'Apartments',
        properties: 'Aparthotels',
        locations: 'Standorte',
      },
      searchForm: {
        arrival: 'Ankunft',
        departure: 'Abfahrt',
        location: 'Lage',
        clear: 'Klar',
        searchApartments: 'Apartment suchen:',
        searchText: 'Suche',
        locationGroups: {
          mountains: 'Berge',
          seaside: 'Meer',
          regions: 'nach Region',
        },
      },
      bookingPage: {
        form: {
          bookNow: 'Buche jetzt und zahlen Sie',
          termsAccept:
            'Ich habe die Bedingungen gelesen und akzeptiert die <2>Allgemeine Geschäftsbedingungen</2> und <6>Buchungsrichtlinien</6>',
          bookingPolicies: 'Buchungsrichtlinien',
          privacyPolicy: 'Datenschutzerklärung',
          privacyPolicyText:
            'Durch Klicken auf die Schaltfläche „Buche jetzt und zahlen Sie” stimmen Sie der Verarbeitung Ihrer persönlichen Daten zu, um die Reservierung abzuschließen. Der Administrator Ihrer Daten ist SALUS - HRM Sp. Z oo. z o.o., pl. Powstańców Śląskich 17A / 222, 53-329 Wrocław',
          guaranteeText:
            'Die Reservierung ist für 18 Stunden garantiert. Zahlungsinformationen und Buchungsdetails werden automatisch an die angegebene E-Mail-Adresse gesendet.',
        },
        steps: {
          place: 'Ort',
          yourDetails: 'Deine Details',
          payment: 'Zahlung',
          summary: 'Zusammenfassung',
        },
        summary: {
          summary: 'Zusammenfassung',
          arrival: 'Ankunft:',
          departure: 'Abfahrt:',
          guests: 'Gäste:',
          costs: 'Kosten',
          total: 'Summe:',
          prepayment: 'Vorauszahlung',
          uponArrival: 'Bei Ankunft',
        },
      },
      costCalculation: {
        clear: 'Klar',
        change: 'Ändern',
        yourStay: 'Dein Aufenthalt',
        total: 'Gesamt',
        prepayment: 'Vorauszahlung',
        uponArrival: 'Bei Ankunft',
        bookNow: 'Buche jetzt',
        book: 'Buche',
        costsSummary: 'Kostenübersicht:',
        chooseDates: 'Wählen Sie Daten',
        additionalCosts: {
          serviceCharge: 'Servicegebühr',
          touristTaxTotal: 'Kurtaxe',
        },
        summaryNightsCount: '{{count}} Nächte × {{price}}',
        form: {
          arrival: 'Ankunft:',
          departure: 'Abreise:',
          guests: 'Gäste:',
          messages: {
            available: 'Termine verfügbar. Buchen Sie jetzt!',
            unavailable: 'Termine nicht verfügbar. Versuchen Sie es mit einem anderen Datumsbereich.',
            chooseTerm: 'Wählen Sie die Daten Ihres Aufenthalts und die Anzahl der Gäste.',
            minNightsUnfulfilled: 'Die Mindestanzahl von Nächten für diese Daten ist {{count}}.',
          },
        },
      },
      payment: {
        title: 'Zahlung',
        waiting: 'Warten auf Bestätigung der Zahlung',
      },
      thankYou: {
        title: 'Vielen Dank für die Zahlung.',
        line1: 'Wir sind sehr froh, dass Sie in unseren Wohnungen bleiben werden.',
        line2: 'Sie erhalten in Kürze eine E-Mail zur Bestätigung Ihrer Buchung.',
        line3: 'Wenden Sie sich bei Fragen an unseren Kundenservice:',
        phone: 'Telefon:',
        email: 'E-mail:',
      },
      paymentError: {
        title: 'Beenden Sie Ihre Reservierung.',
        line1: 'Zur Bestätigung der Reservierung ist eine Zahlung erforderlich.',
        line2: 'Klicken Sie <1>hier</1>, um die Zahlung vorzunehmen.',
      },
      searchPage: {
        resultsCount: '<0>{{location}}:</0> zeigen <3>{{count}}</3> Apartments',
        resultsCountNoLocation: 'Zeigen <1>{{count}}</1> Apartments',
      },
      from: 'Ab',
      book: 'Buchen',
      accommodationNameFormatted: '<0>{{accommodationName}}</0><1>Appartement</1>',
      bedroomCount_zero: 'Studio',
      bedroomCount_one: '1 Schlafzimmer',
      bedroomCount_other: '{{count}} Schlafzimmer',
      guestCount_one: '1 Gast',
      guestCount_other: '{{count}} Gäste',
      checkOnMap: 'Überprüfen Sie auf einer Karte',
      forms: {
        errors: {
          required: 'Feld ist erforderlich',
          email: 'Falsches E-Mail-Format',
        },
      },
    },
  },
};
/* eslint-enable */
