import { ThemeProvider } from 'styled-components';
import theme from 'styles/config';

const withThemeProvider = WrappedComponent => props =>
  (
    <ThemeProvider theme={theme}>
      <WrappedComponent {...props} />
    </ThemeProvider>
  );

export default withThemeProvider;
