import React from 'react';
import URI from 'urijs';

import Result from './Result';

const PropertyResult = ({ property, locale, rootUrl }) => {
  const url = URI(rootUrl).directory(`${locale}/${property.locationSlug}/${property.slug}`).toString();

  return (
    <Result
      primaryText={property.name}
      secondaryText={property.locationName}
      thumbnailUrl={property.thumbnailUrl}
      url={url}
    />
  );
};

export default PropertyResult;
