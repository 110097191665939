import * as hooks from 'hooks';

import ModalBox from './ModalBox';
import { ModalContainer } from './styles';

const Modal = ({ children, className, hideModal, scrollable }) => {
  hooks.useLockBodyScroll();

  return (
    <ModalContainer className={className} scrollable={scrollable}>
      <ModalBox hideModal={hideModal}>{children}</ModalBox>
    </ModalContainer>
  );
};

export default Modal;
