import Icon from 'components/Elements/Icon';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/config';

export const Container = styled.div`
  display: block;
  width: 100%;

  ${breakpoints.desktopUp} {
    margin: 2.4rem;
    background: white;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  height: calc(100% + 0.1rem);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: ${props => (props.scrollable ? 'flex-start' : 'center')};
  justify-content: space-around;
  background: ${colors.white};
  overflow-y: auto;
  overflow-x: hidden;
  transform: translated3d(0, 0, 0);
  z-index: 2000;

  ${breakpoints.desktopUp} {
    padding: 2.4rem;
    background: rgba(0, 0, 0, 0.7);
  }
`;

export const ModalBoxContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  height: auto;
  background: white;

  ${breakpoints.desktopUp} {
    width: 50rem;
    min-height: 0;
    height: auto;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 5.4rem;
  border-bottom: 0.1rem solid ${colors.mercury};
  pointer-events: none;
`;

export const ModalTitle = styled.div`
  color: ${colors.mineShaft};
  font-size: 1.6rem;
  font-weight: 300;
`;

export const ModalContent = styled.div`
  height: auto;
  overflow-x: hidden;
  overflow-scrolling: touch;
  padding: 2rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
  height: 100%;
  background: none;
  border: none;
  pointer-events: auto;
`;

export const CloseIcon = styled(Icon)`
  font-size: 1.8rem;
  color: ${colors.sun};
`;
