import React from 'react';
import URI from 'urijs';

import Result from './Result';

const LocationResult = ({ location, locale, rootUrl }) => {
  const url = URI(rootUrl).directory(`${locale}/${location.slug}`).toString();

  return <Result primaryText={location.name} thumbnailUrl={location.thumbnailUrl} url={url} />;
};

export default LocationResult;
