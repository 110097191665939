/*
 * COLORS
 */

export const colors = {
  black: '#000000',
  boulder: '#797676',
  cinnabar: '#DC3A1D',
  concrete: '#f2f2f2',
  dimgray: '#555555',
  error: '#FFECEC',
  facebook: '#3B5998',
  gainsboro: '#E2E2E2',
  goldenTainoi: '#FFC554',
  gray: '#8E8E8E',
  grayRGB: '142, 142, 142',
  honeydew: '#F1FFF3',
  info: '#ECF6FF',
  infoBorder: '#48A2E8',
  lightgreen: '#81DC8E',
  lima: '#7ED321',
  limedSpruce: '#3B4549',
  mariner: '#2E79C6',
  mercury: '#E7E7E7',
  mineShaft: '#3D3D3D',
  mistyrose: '#FFE8E9',
  red: '#FF0523',
  seashell: '#F1F1F1',
  shark: '#2B2D2E',
  silver: '#CDCDCD',
  success: '#F5FFF5',
  successBorder: '#9FDD9F',
  sun: '#FBAD17',
  twitter: '#4099FF',
  warning: '#FFF6EC',
  warningBorder: '#FBAD17',
  white: '#FFF',
  whitesmoke: '#EFEFEF',
  wildSand: '#F5F5F5',
  wildSandDarker: '#E3E3E3',
};

/*
 * BREAKPOINTS
 */

export const breakpoints = {
  smallPhoneOnly: '@media (max-width: 349px)',
  phoneOnly: '@media (max-width: 499px)',
  phoneLandscapeUp: '@media (min-width: 500px)',
  tabletPortraitUp: '@media (min-width: 600px)',
  tabletLandscapeUp: '@media (min-width: 900px)',
  desktopUp: '@media (min-width: 1200px)',
  bigDesktopUp: '@media (min-width: 1800px)',
};

/*
 * SIZES
 */

export const sizes = {
  sideWidth: '30rem',
};

/*
 * ICONS
 */

export const icons = {
  iconPhoneOld: '\\e922',
  iconPhoneHandset: '\\e921',
  iconLock: '\\e920',
  iconPicture: '\\e91b',
  iconSun: '\\e91c',
  iconCoffeeCup: '\\e91d',
  iconDinner: '\\e91e',
  iconMap: '\\e91f',
  iconFacebook: '\\e91a',
  iconFacebookF: '\\e91a',
  iconToggle: '\\e919',
  iconPencil: '\\e802',
  iconSliders: '\\e917',
  iconSort: '\\e918',
  iconHome: '\\e915',
  iconFilter: '\\e912',
  iconArrowLeft: '\\e90e',
  iconArrowRight: '\\e90f',
  iconBed: '\\e900',
  iconClose: '\\e916',
  iconDot: '\\e905',
  iconFacebookSquare: '\\e914',
  iconFrom: '\\e906',
  iconGuests: '\\e907',
  iconKey: '\\e903',
  iconLinkedinSquare: '\\e913',
  iconMoney: '\\e904',
  iconPhone: '\\e90c',
  iconPin: '\\e908',
  iconRoomType: '\\e90a',
  iconSearch: '\\e90b',
  iconSurface: '\\e901',
  iconTick: '\\e902',
  iconTickCircle: '\\e90d',
  iconTo: '\\e909',
  iconTwitter: '\\e911',
  iconTwitterSquare: '\\e910',
};

export default {
  colors: {
    border: '#e7e7e7',
    error: '#FFECEC',
    info: '#ECF6FF',
    infoBorder: '#48A2E8',
    primary: '#fbad18',
    secondary: '#7ED321',
    success: '#F5FFF5',
    successBorder: '#9FDD9F',
    text: '#8E8E8E',
    textDarker: '#3d3d3d',
    warning: '#FFF6EC',
    warningBorder: '#FBAD17',
    navigation: {
      linkHoverBg: colors.wildSand,
    },
  },
};
