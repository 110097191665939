import React from 'react';
import URI from 'urijs';

import Result from './Result';

const AccommodationResult = ({ accommodation, rootUrl, locale }) => {
  const secondaryText = `${accommodation.propertyName}, ${accommodation.locationName}`;

  const url = URI(rootUrl)
    .directory(`${locale}/${accommodation.locationSlug}/${accommodation.propertySlug}/${accommodation.slug}`)
    .toString();

  return (
    <Result
      primaryText={accommodation.name}
      secondaryText={secondaryText}
      thumbnailUrl={accommodation.thumbnailUrl}
      url={url}
    />
  );
};

export default AccommodationResult;
