import styled from 'styled-components';
import { breakpoints, colors } from 'styles/config';

export const Navigation = styled.nav`
  height: 100%;
  align-items: center;
  flex-grow: 2;
  justify-content: flex-end;
  position: static;
  padding-top: 0;
  background: none;
  box-shadow: none;
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  overflow: visible;
  height: 100%;
  z-index: 2003;
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1.5rem 2rem;
  border-bottom: 0.1rem solid ${colors.wildSand};
  color: ${props => props.theme.colors.textDarker};
  font-size: 1.5rem;
  font-weight: 300;
  text-decoration: none;
  letter-spacing: 0.01rem;

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.navigation.linkHoverBg};
  }

  &.accent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: ${colors.white};
    background: ${colors.mariner};
  }

  ${breakpoints.tabletLandscapeUp} {
    display: flex;
    width: auto;
    height: 100%;
    margin-right: ${props => (props.last ? '2rem' : '3rem')};
    padding: 0;
    border-bottom: 0;

    &:hover,
    &:focus {
      background: none;
    }

    &.accent {
      padding: 0 2rem;
      height: 4.5rem;
    }
  }
`;
