import React from 'react';
import { useTranslation } from 'react-i18next';
import URI from 'urijs';

import { Link } from './styles';

const StaticPageLink = ({ rootUrl, locale, page }) => {
  const { t } = useTranslation();
  const routeName = t(`routes.${page}`);

  const url = URI(rootUrl).path(`${locale}/${routeName}`).toString();

  return <Link href={url}>{t(`navigation.${page}`)}</Link>;
};

export default StaticPageLink;
