import blank from 'images/blank.jpg';
import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/config';

const Container = styled.a`
  display: flex;
  width: 100%;
  background: white;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;

  ${breakpoints.tabletLandscapeUp} {
    padding: 1rem;

    &:hover {
      background: ${colors.whitesmoke};
    }
  }
`;

const Image = styled.img`
  display: block;
  width: 5.5rem;
  margin-right: 1rem;

  ${breakpoints.tabletLandscapeUp} {
    width: 9rem;
    margin-right: 2rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: column;

  ${breakpoints.tabletLandscapeUp} {
    flex-direction: row;
  }
`;

const Text = styled.div`
  display: inline-block;
  width: auto;
  word-spacing: normal;
  white-space: nowrap;
`;

const PrimaryText = styled(Text)`
  font-size: 1.4rem;
  color: ${colors.mineShaft};
  margin-right: 1rem;

  ${breakpoints.tabletLandscapeUp} {
    font-size: 1.8rem;
  }
`;

const SecondaryText = styled(Text)`
  font-size: 1rem;
  color: ${colors.boulder};

  ${breakpoints.tabletLandscapeUp} {
    font-size: 1.4rem;
  }
`;

const Result = ({ url, primaryText, secondaryText, thumbnailUrl }) => {
  return (
    <Container href={url}>
      <Image alt={primaryText} src={thumbnailUrl || blank} />
      <TextContainer>
        <PrimaryText>{primaryText}</PrimaryText>
        <SecondaryText>{secondaryText}</SecondaryText>
      </TextContainer>
    </Container>
  );
};

Result.defaultProps = {
  url: 'https://google.com',
  primaryText: 'Primary',
  secondaryText: null,
};

export default Result;
