import queryString from 'query-string';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import history from 'utils/history';

export const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden';
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
};

const parseSearch = location => queryString.parse(location.search, { arrayFormat: 'comma' });

export const useHistory = () => {
  const [initialized, setInitialized] = useState(false);
  const forceUpdate = useForceUpdate();
  const [query, setQueryState] = useState({ guests: 2 });

  useEffect(() => {
    const unlisten = history.listen(location => {
      setQueryState(parseSearch(location));
      forceUpdate();
    });

    return unlisten;
  }, [history]);

  useEffect(() => {
    setQueryState(parseSearch(history.location));
    setInitialized(true);
  }, []);

  const setQuery = useCallback(
    obj => {
      const newQuery = {
        ...parseSearch(history.location),
        ...obj,
      };

      const newQueryString = `?${queryString.stringify(newQuery, {
        arrayFormat: 'comma',
        skipEmptyString: true,
        skipNull: true,
      })}`;

      history.push({ search: newQueryString });
    },
    [history.location.search]
  );

  return { query, setQuery, initialized };
};
