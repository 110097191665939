import currency from 'currency.js';
import each from 'lodash/each';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
export const compact = obj => pickBy(obj, value => value && value.length);
export const accommodationPageParams = params => omit(compact(params), ['location']);
export const noActionsReducer = (state = {}) => state;
export const formatErrors = errors => {
  const newErrors = {};

  each(errors, (value, property) => {
    if (isArray(value)) {
      set(newErrors, [property], value.sort()[0]);
    } else {
      each(value, (deepValue, deepProperty) => {
        set(newErrors, [property, deepProperty], deepValue.sort()[0]);
      });
    }
  });

  return newErrors;
};

export const getValidateStatus = ({ touched, error, asyncValidating, validating }) => {
  if (!touched) {
    return null;
  }

  if (error) {
    return 'error';
  }
  if (asyncValidating || validating) {
    return 'validating';
  }

  return 'success';
};

export const getFormikFieldStatus = ({ isValid, fieldError, fieldTouched, isValidating }) => {
  if (!isValid && fieldTouched && fieldError) {
    return 'error';
  }

  if (!isValidating && fieldTouched && fieldError) {
    return 'error';
  }

  if (!isValidating && fieldTouched && !fieldError) {
    return 'success';
  }

  if (fieldTouched && isValidating) {
    return 'validating';
  }

  return 'normal';
};

export const getValidationProps = meta => ({
  help: meta.error || null,
  validateStatus: getValidateStatus(meta),
});

export const canUseDOM = () => !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const formatMoney = (amount, cur = null) => {
  const parsedAmount = currency(amount);
  return currency(parsedAmount, {
    separator: '.',
    decimal: ',',
    symbol: cur,
    formatWithSymbol: !!cur,
    pattern: '# !',
    negativePattern: '-# !',
  })
    .format()
    .trim();
};

export const trackGoal = (goalName, props = {}) => {
  if (window && window.plausible) {
    window.plausible(goalName, { props });
  }
};

export const trackRevenueGoal = (goalName, amount, props = {}) => {
  if (window && window.plausible) {
    window.plausible(goalName, { revenue: { currency: 'PLN', amount: amount }, props });
  }
};
