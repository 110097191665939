import * as hooks from 'hooks';
import { branch, renderNothing } from 'recompose';

import ModalBox from './ModalBox';
import { ModalContainer } from './styles';

const Modal = ({ className, visible, children, hideModal, scrollable, title }) => {
  if (!visible) {
    return null;
  }

  hooks.useLockBodyScroll();

  return (
    <ModalContainer className={className} scrollable={scrollable}>
      <ModalBox hideModal={hideModal} title={title}>
        {children}
      </ModalBox>
    </ModalContainer>
  );
};

export default Modal;
