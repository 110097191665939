import Gate from 'adapters/Gate';
import Modal from 'components/Layout/Modal';
import debounce from 'lodash/debounce';
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'react-loading';
import { colors } from 'styles/config';

import AccommodationResult from './AccommodationResult';
import LocationResult from './LocationResult';
import PropertyResult from './PropertyResult';
import Result from './Result';
import * as Styled from './styled';

const Search = ({ rootUrl, locale, isOpen, onClose }) => {
  const { t } = useTranslation();

  const input = createRef();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const delayedQuery = useCallback(
    debounce(query => {
      setIsLoading(true);

      Gate.get(`/search/${query}`)
        .then(({ data }) => {
          if (data.results && data.results.data) {
            setResults(data.results.data.map(result => result.attributes));
          }
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    if (isOpen) {
      input.current.focus();
    }
  }, [isOpen, input]);

  const accommodations = useMemo(() => {
    return results.filter(result => result.type === 'Accommodation');
  }, [results]);

  const properties = useMemo(() => {
    return results.filter(result => result.type === 'Property');
  }, [results]);

  const locations = useMemo(() => {
    return results.filter(result => result.type === 'Location');
  }, [results]);

  const handleChange = useCallback(e => {
    const newValue = e.target.value;

    setQuery(newValue);

    if (newValue.length) {
      delayedQuery(newValue);
    } else {
      setResults([]);
    }
  }, []);

  return (
    <Modal hideModal={onClose} id="search-modal" visible={isOpen} minimal scrollable>
      <div className="ss-modal-content">
        <h2>{t('searchModal.title')}</h2>
        <Styled.InputContainer>
          <Styled.Input
            ref={input}
            placeholder={t('searchModal.inputPlaceholder')}
            type="text"
            onChange={handleChange}
          />
        </Styled.InputContainer>
        {isLoading ? (
          <Styled.LoadingContainer>
            <Loading color={colors.sun} delay={0} height={25} type="spin" width={25} />
          </Styled.LoadingContainer>
        ) : null}
        <Styled.Results>
          {query.length ? (
            <>
              {!isLoading && query.length && !results.length ? t('searchModal.noResults') : null}
              {locations.length ? (
                <Styled.ResultGroup>
                  <Styled.ResultGroupTitle>{t('searchModal.locations')}</Styled.ResultGroupTitle>
                  {locations.map(location => (
                    <LocationResult key={location.id} locale={locale} location={location} rootUrl={rootUrl} />
                  ))}
                </Styled.ResultGroup>
              ) : null}
              {properties.length ? (
                <Styled.ResultGroup>
                  <Styled.ResultGroupTitle>{t('searchModal.properties')}</Styled.ResultGroupTitle>
                  {properties.map(property => (
                    <PropertyResult key={property.id} locale={locale} property={property} rootUrl={rootUrl} />
                  ))}
                </Styled.ResultGroup>
              ) : null}
              {accommodations.length ? (
                <Styled.ResultGroup>
                  <Styled.ResultGroupTitle>{t('searchModal.accommodations')}</Styled.ResultGroupTitle>
                  {accommodations.map(accommodation => (
                    <AccommodationResult
                      key={accommodation.id}
                      accommodation={accommodation}
                      locale={locale}
                      rootUrl={rootUrl}
                    />
                  ))}
                </Styled.ResultGroup>
              ) : null}
            </>
          ) : null}
        </Styled.Results>
      </div>
    </Modal>
  );
};

export default Search;
