import deFlag from 'images/germany_flag.png';
import plFlag from 'images/poland_flag.png';
import enFlag from 'images/uk_flag.png';
import pull from 'lodash/pull';
import React from 'react';

import { Container, CurrentLocale, CurrentLocaleName, Image, Li, Link, LocaleName, Locales } from './styles';

const localeData = {
  pl: {
    image: plFlag,
    name: 'Polski',
  },
  en: {
    image: enFlag,
    name: 'English',
  },
  de: {
    image: deFlag,
    name: 'Deutsch',
  },
};

const LocaleSwitcher = ({ availableLocales, currentLocale }) => {
  const locales = pull(availableLocales, currentLocale);

  return (
    <Container>
      <CurrentLocale>
        <Image alt={currentLocale} src={localeData[currentLocale].image} />
        <CurrentLocaleName>{localeData[currentLocale].name}</CurrentLocaleName>
      </CurrentLocale>
      <Locales>
        {locales.map(locale => (
          <Li key={locale}>
            <Link data-turbolinks={false} href={`/${locale}/choose_language`}>
              <Image alt={locale} src={localeData[locale].image} />
              <LocaleName>{localeData[locale].name}</LocaleName>
            </Link>
          </Li>
        ))}
        <Li key={currentLocale} inactive>
          <Link data-turbolinks={false} href={`/${currentLocale}/choose_language`}>
            <Image alt={currentLocale} src={localeData[currentLocale].image} />
            <LocaleName>{localeData[currentLocale].name}</LocaleName>
          </Link>
        </Li>
      </Locales>
    </Container>
  );
};

export default LocaleSwitcher;
