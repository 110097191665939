import Icon from 'components/Elements/Icon';
import styled from 'styled-components';
import { breakpoints, colors } from 'styles/config';

export const Button = styled.button`
  height: auto;
  margin-bottom: 3rem;
  background: none;

  ${breakpoints.tabletPortraitUp} {
    margin-bottom: 2rem;
  }
`;

export const CloseIcon = styled(Icon)`
  font-size: 1.8rem;
  color: #aaa;
`;

export const ModalBoxContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  height: auto;
  padding: 3rem;
  background: ${colors.white};

  ${breakpoints.tabletPortraitUp} {
    width: 55rem;
    min-height: 0;
    height: auto;
    padding: 2rem;
  }

  ${breakpoints.tabletLandscapeUp} {
    width: 85rem;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  height: calc(100% + 0.1rem);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: ${props => (props.scrollable ? 'flex-start' : 'center')};
  justify-content: space-around;
  background: ${colors.white};
  overflow-y: auto;
  overflow-x: hidden;
  transform: translated3d(0, 0, 0);
  z-index: 2000;

  ${breakpoints.tabletPortraitUp} {
    padding: 2.4rem;
    background: rgba(0, 0, 0, 0.7);
  }
`;
